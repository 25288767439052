import loading from "./js/loading";
import use from "./js/use";
import registration from "./js/registration";
import ic_registration from "./js/ic-registration";
import qa from "./js/qa";
loading.load();
use.toggleTab();
registration.toggleTab();
ic_registration.toggleTab();
qa.toggle();

import './scss/app.scss';
