export default class Qa {
  static toggle() {
    let triggers = document.querySelectorAll('.js-dt');
    let contents = document.querySelectorAll('.js-page_use-tab_contents');
    Array.from(triggers).forEach(trigger => {
      trigger.addEventListener("click", (e) => {
        e.preventDefault();

        let target = e.currentTarget;
        let id = target.getAttribute('data-id');
        let dd = document.querySelector('#' + id);
        if (target.classList.contains('active')) {
          target.classList.remove('active');
          dd.classList.remove('active');
        } else {
          target.classList.add('active');
          dd.classList.add('active');
        }
      });
    });
  }
}
