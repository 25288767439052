export default class Registration {
  static toggleTab() {
    let triggers = document.querySelectorAll('.js-page_registration-tab_trigger');
    let contents = document.querySelectorAll('.js-page_registration-tab_contents');
    Array.from(triggers).forEach(trigger => {
      trigger.addEventListener("click", (e) => {
        e.preventDefault();

        Array.from(contents).forEach(target => {
          target.style.display = "none";
        });

        Array.from(triggers).forEach(target => {
          if (target.classList.contains('active')) {
            target.classList.remove('active');
          } else {
            target.classList.add('active');
            document.querySelector('.page-registration .js-' + target.getAttribute('data-contents')).style.display = "block";
          }
        });
      });
    });
  }
}
